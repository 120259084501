
const initOfficialStampCheckBox = () => {
  console.log("INIT OFFICIAL STAMP CHECKBOX")
  const checkbox = document.querySelector("#stamped_image_checkbox")

  console.log({checkbox})

  if (checkbox) {
    checkbox.addEventListener('change', (e) => {
      if (e.target.checked) {
        document.querySelector("#stamped_image_container").style.display = 'block'
      } else {
        document.querySelector("#stamped_image_container").style.display = 'none'
      }
    });
  }

  const drySealCheckbox = document.querySelector("#dry_seal_image_checkbox")
  console.log({drySealCheckbox})
  if (drySealCheckbox) {
    drySealCheckbox.addEventListener('change', (e) => {
      if (e.target.checked) {
        document.querySelector("#dry-seal-image").style.display = 'block'
      } else {
        document.querySelector("#dry-seal-image").style.display = 'none'
      }
    });
  }

//   <% if @admission.user.company.show_mhw_stamp %>
//     <div id="" style="display: <%= "block" %>; position: relative">
//   <%= image_tag CompanyProfile.profile.dry_seal, style: 'width: 200px; height: 200px; margin-top: -350px !important; right: 0px !important; position: absolute', id: 'dry-seal-image' if CompanyProfile.profile.dry_seal.attached? %>
// <p style="right: 0; position: absolute; margin-top: -160px;" class="print-hidden">
//     <input id="dry_seal_image_checkbox" type="checkbox" class="print-hidden" <%= visible ? "checked" : "" %> >
//     DRY SEAL
//   </p>
// </div>
//   <% end %>
}

const initYesNoCheckBox = () => {
  console.log("checkbox starting")

  initOfficialStampCheckBox()

  let yesNoContainer = document.querySelectorAll('.yes-no-checkbox-container');

  console.log({yesNoContainer})

  // Iterate over each selected div
  yesNoContainer.forEach((div) => {

    


    // Find the checkbox input element
    let checkbox = div.querySelector('input[type="checkbox"]');

    // Create a new label element with the text 'Yes'
    let labelYes = document.createElement('span');
    labelYes.classList.add('yes-no-checkbox-label');
    labelYes.textContent = 'Yes';

    // Append the label to the checkbox input element
    checkbox.parentNode.insertBefore(labelYes, checkbox.nextSibling);

    // Create a new checkbox input element for 'No'
    let checkboxNo = document.createElement('input');
    checkboxNo.type = 'checkbox';
    checkboxNo.classList.add('table-input-checkbox');
    checkboxNo.checked = !checkbox.checked;
    
    // Create a new label element with the text 'No'
    let labelNo = document.createElement('span');
    labelNo.classList.add('yes-no-checkbox-label');
    labelNo.textContent = 'No';

    // Append the 'No' checkbox and label to the parent node
    checkbox.parentNode.appendChild(checkboxNo);
    checkbox.parentNode.appendChild(labelNo);

    checkbox.addEventListener('change', (e) => {
      checkboxNo.checked = !checkbox.checked;
    });

    checkboxNo.addEventListener('change', (e) => {
      checkbox.checked = !checkboxNo.checked;
    });
  });
}

export default initYesNoCheckBox